import { useNuxtApp } from '#imports';
import type { FetchError } from 'ofetch';
import type { Ref, ComputedRef } from 'vue';
import type { GetCountriesApp } from '~/helpers/parsers/CountriesDTO';

type CountryStoreType = {
  // countryListForLocale: Ref<GetCountriesApp[] | null>;
  setCountries: (country: GetCountriesApp[]) => void;
  getCountries: () => Promise<GetCountriesApp[] | null>;
  countryList: Ref<GetCountriesApp[] | null>
}
export const countriesStore = defineStore('countries-store', (): CountryStoreType => {
  const { $api, $i18n } = useNuxtApp();
  const countryList = ref<GetCountriesApp[] | null>(null);
  // const countryListForLocale = ref<GetCountriesApp[] | null>(null);
  // const countryList: ComputedRef<GetCountriesApp[] | null> = computed(() => {
  //   const locale: string = String($i18n?.locale?.value || $i18n?.defaultLocale);
  //   console.log('locale: ', locale);
  //
  //   return [...(countryListForLocale.value || [])]
  //     .map((item: GetCountriesApp) => {
  //       const langs: {[key: string]: string} = {...(item?.langs || {})};
  //       return {
  //         ...item,
  //         title: langs?.[locale] || langs?.en || item.title || ''
  //       }
  //     })
  // })

  function setCountries (country: GetCountriesApp[]) {
    // countryListForLocale.value = country;
    countryList.value = country;
  }

  async function getCountries (): Promise<GetCountriesApp[] | null> {
    const res: GetCountriesApp[] | null = await $api('/api-front/get-countries', {
      baseURL: '/'
    }).catch((err: FetchError) => {
      console.log('error during getting countries:', err.data);
      return null;
    });

    if (res && res.length) {
      setCountries(res);

      return res;
    }

    return null;
  }

  return {
    countryList,
    setCountries,
    getCountries,
    // countryListForLocale
  };
});
